/** @format */

import React, { useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import {Link} from 'react-router-dom'
import Button from "react-bootstrap/Button"
import "bootstrap/dist/css/bootstrap.min.css"
import MaterialReactTable from 'material-react-table';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';

import styles from "../styles/admin.module.css";
import "../styles/dropdown.css"
import avatar from "../assets/logo.png";
import signout from "../assets/log-out.png"

const appsEndPoint = "https://sms-client.viden.rw/messaging/twese/myApps";
const notificationsEndPoint = "https://sms-client.viden.rw/messaging/twese/notification";
const whoEndPoint = "https://sms-client.viden.rw/messaging/who"

const logout = () =>{
  localStorage.removeItem('userToken');
  window.location.href = '/'
}


const tableStyle = {
  borderRadius: '10px',
  overflow: 'hidden',
};

export default function User() {

  const [apps, setApps] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(false);
  const [senderId, setSenderId] = useState();
  const [numberSMS, setNumberSMS] = useState();
  const [name, setName] = useState();
  const [initials, setInitials] = useState();
  const [who, setWho] = useState()

  const getInitials = (str) => {
    let words = str.split(' ');
    let initials = '';
    for (let i = 0; i < words.length; i++) {
      initials += words[i][0];
    }
    return initials.toUpperCase();
  }

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e)=>{
      if(!menuRef.current.contains(e.target)){
        setOpen(false)
      }
    };

    document.addEventListener("mousedown", handler)

    return() =>{
      document.removeEventListener("mousedown", handler);
    }
  })

  function DropdownItem(props){
    return(
      <li className={styles.dropdownItem}>
        <img src={props.img}></img>
        <Link onClick={props.onClick}>{props.text}</Link>
      </li>
    );
  }


  const notificationColumns = [
    {
      header: "Sender ID",
      accessorKey: "sender.title",
    },
    {
      header: "Recipients",
      accessorKey: "recipients",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Created At",
      accessorKey: "createdAt",
    },
    {
      header: "Updated At",
      accessorKey: "updatedAt",
    },
  ];

  useEffect(() => {
    const fetchData = async()=>{
      const token = JSON.parse(localStorage.getItem("userToken"));

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.get(appsEndPoint, config);
        const { name, senderId, numberSMS} = response.data[0];
        setSenderId(senderId);
        setNumberSMS(numberSMS);
        setName(name);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);


  // notifications Endpoint

  useEffect(() => {
    const fetchData = async()=>{
      const token = JSON.parse(localStorage.getItem("userToken"));

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };<img src={avatar}  alt="logo" />

      axios
        .get(notificationsEndPoint, config)
        .then((response) => {
          setNotifications(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData()
  }, []);

  useEffect(() => {
    const fetchData = async()=>{
      const token = JSON.parse(localStorage.getItem("userToken"));

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };<img src={avatar}  alt="logo" />

      axios
        .get(whoEndPoint, config)
        .then((response) => {
          const { firstName, lastName} = response.data;
          const names = firstName + " " + lastName
          const initials = getInitials(names)
          setInitials(initials)
          setWho(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData()
  }, []);



  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === 'light'
                ? 'rgb(254,255,244)' //random light yellow color for the background in light mode
                : '#000', //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: 'none', //customize typography styles for all buttons in table by default
            fontSize: '1.2rem',
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: '1.1rem', //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: 'pink', //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme],
  );

  return (
    <div className={styles.body}>
      <div className={styles.header}>
        <div className={styles.headerLogo}>
          <img src={avatar} className="logoImg" alt="logo" />
        </div>
        <div className={styles.notifications}>
          <h4>{senderId}</h4>
          <h4>{numberSMS}</h4>
        </div>
        <div className="menu_container" ref={menuRef}>
          <div className={styles.menu_trigger} onClick={()=>{setOpen(!open)}}>
              <h3>{initials}</h3>
          </div>
          <div className={`${styles.dropdown_menu} ${open? styles.active : styles.inactive}`}>
            <ul>
              <DropdownItem img={signout} text={"Sign Out"} onClick={logout}/>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.appKeys}>
        <div className={styles.userApplications} style={{ height: "200px" }}>
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            columns={notificationColumns}
            data={notifications}
            enableRowSelection
            enableColumnOrdering
            enablePinning
          />
        </ThemeProvider>
        </div>
      </div>
    </div>
  );
}
