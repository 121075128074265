// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Profile_glass__d8GZw {\n\n    height: 83.333333%;\n\n    width: 83.333333%\n}\n\n.Profile_profile_img__nIq7t {\n\n    width: 10rem;\n\n    cursor: pointer\n}\n\n.Profile_textbox__A\\+ja7 {\n\n    width: 75%\n}", "",{"version":3,"sources":["webpack://./src/styles/Profile.module.css"],"names":[],"mappings":"AACI;;IAAA,kBAAkB;;IAAlB;AAAkB;;AAIlB;;IAAA,YAA0B;;IAA1B;AAA0B;;AAI1B;;IAAA;AAAY","sourcesContent":[".glass{\n    @apply h-5/6 w-5/6;\n}\n\n.profile_img{\n    @apply w-40 cursor-pointer;\n}\n\n.textbox{\n    @apply w-3/4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"glass": "Profile_glass__d8GZw",
	"profile_img": "Profile_profile_img__nIq7t",
	"textbox": "Profile_textbox__A+ja7"
};
export default ___CSS_LOADER_EXPORT___;
