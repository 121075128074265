import toast  from "react-hot-toast"

/** validate login page username**/
export async function usernameValidate(values){
    const errors = usernameVerify({}, values);

    return errors;
}

/** validate Pasword page username**/
export async function passwordValidate(values){
    const errors = passwordVerify({}, values);

    return errors
}

/** validate Reset Pasword page username**/
export async function resetPasswordValidation(values){
    const errors = passwordVerify({}, values);

    if(values.password !== values.comfirm_pwd){
        errors.exist = toast.error("Password doesn't match..!")
    }
}

/** validate Register page username**/
export async function registerValidate(values){
    const errors = usernameVerify({}, values);
    firstNameVerify(errors, values)
    lastNameVerify(errors, values)
    passwordVerify(errors, values)
    emailVerify(errors, values)
    phoneNumberVerify(errors, values)  

    return errors
}

/** validate Profile page username **/
export async function profileValidate(values){
    const errors = emailVerify({}, values);
    return errors;

}

/**************************************************** */

/** validate Password **/
function passwordVerify(errors = {}, values){

    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if(!values.password){
        errors.password = toast.error("Password Required..!");
    }else if(values.password.includes(" ")){
        errors.password = toast.error("Wrong Password..!")
    }else if(values.password.length < 4){
        errors.password = toast.error("Password must be more than 4 characters long..!")
    }else if(!specialChars.test(values.password)){
        errors.password = toast.error("Password must have special characters ..!")
    }

    return errors
}

/** validate username **/
function usernameVerify(errors = {}, values){
    if(!values.username){
        errors.username = toast.error("Username Required..!")
    }else if(values.username.includes(" ")){
        errors.username = toast.error("Invarid Username..!")
    }

    return errors
}

/** validate email **/
function emailVerify(errors = {}, values){
    if(!values.email){
        errors.email = toast.error("Email Required..!")
    }else if(values.email.includes(" ")){
        errors.email = toast.error("Wrong Email..!")
    }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)){
        errors.email = toast.error("Invalide Email address..!")
    }

    return errors
}

/** validate firstname **/
function firstNameVerify(errors = {}, values){
    if(!values.firstName){
        errors.firstName = toast.error("First Name required..!")
    }else if(values.firstName.includes(" ")){
        errors.firstName = toast.error("Please Enter a valid name..!")
    }

    return errors
}

/** validate fastname **/
function lastNameVerify(errors = {}, values){
    if(!values.lastName){
        errors.lastName = toast.error("Last Name required..!")
    }else if(values.lastName.includes(" ")){
        errors.lastName = toast.error("Please Enter a valid name..!")
    }

    return errors
}

/** validate fastname **/
function phoneNumberVerify(errors = {}, values){
    if(!values.phoneNumber){
        errors.phoneNumber = toast.error("Phone number required..!")
    }else if(values.phoneNumber.substring(0, 3) != 250){
        errors.phoneNumber = toast.error("Your number should start with 250..!")
    }else if(values.phoneNumber.length != 12){
        errors.phoneNumber = toast.error("the number you have entered is incorrect..!")
    }

    return errors
}