import React, {useState} from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import {Toaster} from 'react-hot-toast'
import {useFormik} from 'formik'
import {registerValidate} from '../helper/validate'

import styles from '../styles/Username.module.css'

const registerEndPoint = "https://sms-client.viden.rw/messaging/signup"

export default function Register() {

  const [file, setFile] = useState()

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email:'',
      username: '',
      password: ''
    },
    validate: registerValidate,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async values =>{
      values = await Object.assign(values, {profile : file || ''})
      const { profile, ...rest } = values;

      try{
        const response = await axios.post(registerEndPoint, rest);
        console.log(response.status)
      }catch(error){
        console.error(error)
      }
    }
  })

  /** formik doesn't support file upload, I need to create this handle**/


  return (
    <div className="container mx-auto">
      <Toaster position='top-center' reverseOrder={false}></Toaster>
      <div className='flex justify-center items-center h-screen'>
        <div  styles={{width: "45%"}}>

          <div className='title flex flex-col items-center'>
            <h4 className='text-5xl font-bold'>Register</h4>
            <span className='py-4 text-xl w-2/3 text-center text-gray-500'>
              Happy to have you!
            </span>
          </div>

          <form className='py-1' onSubmit={formik.handleSubmit}>
            <div className='textbox flex flex-col items-center gap-6'>
              <input {...formik.getFieldProps('firstName')} className={styles.textbox} type='text' placeholder='Firstname*'/>
              <input {...formik.getFieldProps('lastName')} className={styles.textbox} type='text' placeholder='Lastname*'/>
              <input {...formik.getFieldProps('phoneNumber')} className={styles.textbox} type='text' placeholder='Phone number*'/>
              <input {...formik.getFieldProps('email')} className={styles.textbox} type='text' placeholder='Email*'/>
              <input {...formik.getFieldProps('username')} className={styles.textbox} type='text' placeholder='Username*'/>
              <input {...formik.getFieldProps('password')} className={styles.textbox} type='password' placeholder='Password*'/>
              <button className={styles.btn} type='submit'>Register</button>
            </div>

            <div className='text-center py-4'>
              <span className='text-gray-500'>Already registered? <Link className='text-red-500' to='/'>Login Now</Link></span>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
