import React from 'react'
import {Toaster} from 'react-hot-toast'
import {Link} from 'react-router-dom'

export default function PendingApproval() {

  return (
    <div className="container mx-auto">
      <Toaster position='top-center' reverseOrder={false}></Toaster>
      <div className='flex justify-center items-center h-screen'>
        <div style={{width : "50%"}}>

          <div className='title flex flex-col items-center'>
            <h4 className='text-5xl font-bold'>Pending Approval!</h4>
            <span className='py-4 text-xl w-2/3 text-center text-gray-500'>
              Please be patient while our agent review your registrration request...
            </span>
            <span className='py-4 text-xl w-2/3 text-center text-gray-500'>
              Contact us +250788551511
            </span>
            <span className='py-4 text-xl w-2/3 text-center text-gray-500'>
              Home sweet <Link className='text-red-500' to='/'>Home</Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
