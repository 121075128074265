import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import MaterialReactTable from 'material-react-table';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import {Link} from 'react-router-dom';
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import signout from "../assets/log-out.png"

import styles from '../styles/admin.module.css';
import avatar from '../assets/logo.png';


const applicationsEndPoint = "https://sms-client.viden.rw/messaging/admin/application"
const keysEndPoint = "https://sms-client.viden.rw/messaging/admin/application/keys"
const usersEndPoint = "https://sms-client.viden.rw/messaging/admin/users"
const whoEndPoint = "https://sms-client.viden.rw/messaging/who"

const logout = () =>{
  localStorage.removeItem('userToken');
  window.location.href = '/'
}

function DropdownItem(props){
  return(
    <li className={styles.dropdownItem}>
      <img src={props.img}></img>
      <Link onClick={props.onClick}>{props.text}</Link>
    </li>
  );
}

export default function Admin(){
    const [applications, setApplications] = useState([]);
    const [keys, setKeys] = useState([]);
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [initials, setInitials] = useState();
    const [who, setWho] = useState()

    const getInitials = (str) => {
      let words = str.split(' ');
      let initials = '';
      for (let i = 0; i < words.length; i++) {
        initials += words[i][0];
      }
      return initials.toUpperCase();
    }

    function DropdownItem(props){
      return(
        <li className={styles.dropdownItem}>
          <img src={props.img}></img>
          <Link onClick={props.onClick}>{props.text}</Link>
        </li>
      );
    }

    let menuRef = useRef();

    useEffect(() => {
      let handler = (e)=>{
        if(!menuRef.current.contains(e.target)){
          setOpen(false)
        }
      };

      document.addEventListener("mousedown", handler)

      return() =>{
        document.removeEventListener("mousedown", handler);
      }
    })

    const appColumns = [
      {
        header: "Name", accessorKey:"name"
      },
      {
        header: "Sender ID", accessorKey:"senderId"
      },
      {
        header: "SMS.N", accessorKey:"numberSMS"
      },
      {
        header: "Status", accessorKey:"status"
      },
    ]

    const keysColumns = [
      {
        header: "Entity ID", accessorKey:"entityId"
      },
      {
        header: "Entity Reference", accessorKey:"entityRef", minSize: 10, size:30
      },
      {
        header: "Key", accessorKey:"key"
      }
    ]

    const usersColumns = [
      {
        header: "First Name", accessorKey:"firstName"
      },
      {
        header: "Last Name", accessorKey:"lastName"
      },
      {
        header: "Phone Number", accessorKey:"phoneNumber"
      },
      {
        header: "Email", accessorKey:"email"
      },
      {
        header: "Username", accessorKey:"username"
      },
      {
        header: "Role", accessorKey:"role"
      },
      {
        header: "Status", accessorKey:"status"
      }
    ]

    useEffect(() => {
      const token = JSON.parse(localStorage.getItem("userToken"))
        const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

        axios.get(applicationsEndPoint, config)
          .then(response => {
            setApplications(response.data);
          })
          .catch(error => {
            console.log(error);
          });
    }, []);

    useEffect(() => {
      const token = JSON.parse(localStorage.getItem("userToken"));
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .get(keysEndPoint, config)
        .then((response) => {
          setKeys(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);

    useEffect(() => {
      const token = JSON.parse(localStorage.getItem("userToken"))

        const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

        axios.get(usersEndPoint, config)
          .then(response => {
            setUsers(response.data);
          })
          .catch(error => {
            console.log(error);
          });
    }, []);

    useEffect(() => {
      const fetchData = async()=>{
        const token = JSON.parse(localStorage.getItem("userToken"));

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };<img src={avatar}  alt="logo" />

        axios
          .get(whoEndPoint, config)
          .then((response) => {
            const { firstName, lastName} = response.data;
            const names = firstName + " " + lastName
            const initials = getInitials(names)
            setInitials(initials)
            setWho(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      fetchData()
    }, []);

    const globalTheme = useTheme();

    const tableTheme = useMemo(
      () =>
        createTheme({
          palette: {
            mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
            primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
            info: {
              main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
            },
            background: {
              default:
                globalTheme.palette.mode === 'light'
                  ? 'rgb(254,255,244)' //random light yellow color for the background in light mode
                  : '#000', //pure black table in dark mode for fun
            },
          },
          typography: {
            button: {
              textTransform: 'none', //customize typography styles for all buttons in table by default
              fontSize: '1.2rem',
            },
          },
          components: {
            MuiTooltip: {
              styleOverrides: {
                tooltip: {
                  fontSize: '1.1rem', //override to make tooltip font size larger
                },
              },
            },
            MuiSwitch: {
              styleOverrides: {
                thumb: {
                  color: 'pink', //change the color of the switch thumb in the columns show/hide menu to pink
                },
              },
            },
          },
        }),
      [globalTheme],
    );

    return(
        <div>
          <div className={styles.header}>
            <div>
              <img src={avatar} className={styles.logoImg} alt='logo'/>
            </div>
            <div className="menu_container" ref={menuRef}>
              <div className={styles.menu_trigger} onClick={()=>{setOpen(!open)}}>
                  <h3>{initials}</h3>
              </div>
              <div className={`${styles.dropdown_menu} ${open? styles.active : styles.inactive}`}>
                <ul>
                  <DropdownItem img={signout} text={"Sign Out"} onClick={logout}/>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.appKeys}>
            <div className={styles.applications} style={{height:"150px"}}>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  columns={appColumns}
                  data={applications}
                  enableColumnOrdering
                  enablePinning
                />
              </ThemeProvider>
            </div>
            <div className={styles.keys}>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  columns={keysColumns}
                  data={keys}
                  enableColumnOrdering
                  enablePinning
                />
              </ThemeProvider>
            </div>
          </div>
          <div className={styles.users}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                columns={usersColumns}
                data={users}
                enableColumnOrdering
                enablePinning
              />
            </ThemeProvider>
          </div>
        </div>
    )
}
