import React from 'react'
import { createBrowserRouter, RouterProvider, BrowserRouter as Router, Routes, Route } from 'react-router-dom'

/** import all component **/
import Username from './components/Username';
import Password from './components/Password';
import Register from './components/Register';
import Profile from './components/Profile';
import Recovery from './components/Recovery';
import Reset from './components/Reset';
import PageNotFound from './components/PageNotFound';
import PendingApproval from './components/PendingApproval';
import Admin from './components/Admin';
import User from './components/User';
import ProtectedRoutes from './components/ProtectedRoutes';

/** rout routers **/
// const router = createBrowserRouter([
//     {
//         path: '/',
//         element: <Username></Username>
//     },
//     {
//         path: '/register',
//         element: <Register></Register>
//     }
//     ,
//     {
//         path: '/password',
//         element: <Password></Password>
//     },
//     {
//         path: '/profile',
//         element: <Profile></Profile>
//     },
//     {
//         path: '/recovery',
//         element: <Recovery></Recovery>
//     },
//     {
//         path: '/reset',
//         element: <Reset></Reset>
//     },
//     {
//         path: '/PendingApproval',
//         element: <PendingApproval></PendingApproval>
//     },
//     {
//         path: '/Admin',
//         element: <Admin></Admin>
//     },
//     {
//         path: '/User',
//         element: <User></User>
//     },
//     {
//         path: '*',
//         element: <PageNotFound></PageNotFound>
//     }
// ])

export default function App() {
  return (
    <div>

        <Router>
          <Routes>
            <Route element={<ProtectedRoutes/>}>
              <Route element={<User/>} path="/User"/>
              <Route element={<Admin/>} path="/Admin" />
            </Route>
            <Route element={<Username/>} path="/" />
            <Route element={<Register/>} path="/Register" />
          </Routes>
        </Router>
    </div>
  )
}
