import React, { useState }from 'react'
import {Link} from 'react-router-dom'
import avatar from '../assets/logo.png'
import {Toaster} from 'react-hot-toast'
import {useFormik} from 'formik'
import {usernameValidate} from '../helper/validate'
import axios from 'axios'

import styles from '../styles/Username.module.css'

const loginEndPoint = "https://sms-client.viden.rw/messaging/login"
export default function Username() {
  const [error, setError] = useState(null)  // Define state to handle API errors

  const formik = useFormik({
    initialValues: {
      email: '',
      password:''
    },
    validate: usernameValidate,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async values =>{
      try{
        const response = await axios.post(loginEndPoint, values)
        console.log(response.data)
        const responseCode = response.status

        if (responseCode === 200){
          localStorage.setItem('userToken', JSON.stringify(response.data.token))
          if (response.data.roles === "ROLE_ADMIN") {
            window.location.href = '/admin'
          }else if(response.data.roles === "ROLE_USER"){
            window.location.href = '/user'
          }
        }
      }catch(error){
        setError(error.response.data.message)
      }
    }
  })

  return (
    <div className="container mx-auto">
      <Toaster position='top-center' reverseOrder={false}></Toaster>
      <div className='flex justify-center items-center h-screen'>
        <div>
          <form className='py-1' onSubmit={formik.handleSubmit}>
            <div className='profile flex justify-center px-4'>
              <img src={avatar} className={styles.profile_img} alt='avatar'/>
            </div>

            <div className='textbox flex flex-col items-center gap-6'>
              <input {...formik.getFieldProps('username')} className={styles.textbox} type='text' placeholder='username*' defaultValue={formik.initialValues.username}/>
              <input {...formik.getFieldProps('password')} className={styles.textbox} type='password' placeholder='Password*' defaultValue={formik.initialValues.password}/>
              <button className={styles.btn} type='submit'>Signin</button>
            </div>

            <div className='text-center py-4'>
              <span className='text-gray-500'>Not registered? <Link className='text-red-500' to='/register'>Signup Here!</Link></span>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
